import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import bme_logo from "../../images/logo-removebg-preview.png";
import "./Header.css";
import just_logo from "../../images/logoTop.png";
import Button from "@mui/material/Button";
import whatsappIcon from "../../images/whatsapp_png.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector("nav");
      if (header) {
        const sticky = window.scrollY > 0;
        setIsSticky(sticky);
        header.classList.toggle("sticky", sticky);
      }
    };
    
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // console.log("Click detected outside menu");
        // setMenuOpen(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // console.log("Click detected outside dropdown");
        setDropdownOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => {
      // console.log("Dropdown toggled:", !prev);
      return !prev;
    });
  };

  const handleMenuToggle = () => {
    setMenuOpen((prev) => {
      // console.log("Menu toggled:", !prev);
      return !prev;
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav className="navBar">
      <NavLink
        to="/"
        onClick={() => {
          setMenuOpen(false);
          scrollToTop();
        }}
      >
        <img
          src={isSticky ? just_logo : bme_logo}
          alt="photos"
          className={isSticky ? "Basicimg logo-sticky" : ""}
        />
      </NavLink>
      <a
        href="https://wa.me/+918310491223" // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <img src={whatsappIcon} alt="WhatsApp" />
      </a>
      <div className={isSticky ? "menu1" : "menu"} onClick={handleMenuToggle}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul ref={menuRef} className={menuOpen ? "Open" : ""}>
        <li>
          <NavLink
            to="/"
            onClick={() => {
              setMenuOpen(false);
              scrollToTop();
            }}
          >
            Home
          </NavLink>
        </li>
        <li ref={dropdownRef} onClick={handleDropdownToggle}>
          <NavLink to="/guidelines">Guidelines</NavLink>
          {dropdownOpen && (
            <ul className="dropdown">
              <li>
                <NavLink
                  to="/guidelines/privacy"
                  onClick={() => {
                    setMenuOpen(false);
                    setDropdownOpen(false);
                    scrollToTop();
                  }}
                >
                  Privacy Policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/guidelines/cancellation"
                  onClick={() => {
                    setMenuOpen(false);
                    setDropdownOpen(false);
                    scrollToTop();
                  }}
                >
                  Cancellation Policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/guidelines/subscription"
                  onClick={() => {
                    setMenuOpen(false);
                    setDropdownOpen(false);
                    scrollToTop();
                  }}
                >
                  Legal Policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/guidelines/refunds"
                  onClick={() => {
                    setMenuOpen(false);
                    setDropdownOpen(false);
                    scrollToTop();
                  }}
                >
                  Terms & Conditions
                </NavLink>
              </li>
            </ul>
          )}
        </li>
        <li>
          <NavLink
            to="/about"
            onClick={() => {
              setMenuOpen(false);
              scrollToTop();
            }}
          >
            About
          </NavLink>
        </li>
        <li>
        <a
            href="https://web.bmebharat.com/"
            target="_blank" // Opens in a new tab, you can remove this if you want it to open in the same tab
            rel="noopener noreferrer" // For security reasons, especially with `target="_blank"`
            onClick={() => {
              setMenuOpen(false);
              scrollToTop(); // You can keep this or remove it depending on your use case
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "transparent",
                color: "#075cab",
                border: "1px solid #075cab",
                fontSize: "14px",
                // fontFamily: "Montserrat",
                "&:hover": {
                  backgroundColor: "#e3f2fd",
                  border: "1px solid #075cab",
                  color: "#075cab",
                  fontSize: "14px",
                  // fontFamily: "Montserrat",
                },
              }}
            >
              Sign Up
            </Button>
          </a>
        </li>
        <li>
        <a
            href="https://web.bmebharat.com/"
            target="_blank" // Opens in a new tab, you can remove this if you want it to open in the same tab
            rel="noopener noreferrer" // For security reasons, especially with `target="_blank"`
            onClick={() => {
              setMenuOpen(false);
              scrollToTop(); // You can keep this or remove it depending on your use case
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "transparent",
                color: "#075cab",
                border: "1px solid #075cab",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "#e3f2fd",
                  border: "1px solid #075cab",
                  color: "#075cab",
                  fontSize: "14px",
                },
              }}
            >
              LogIn
            </Button>
          </a>
        </li>
       
      </ul>
    </nav>
  );
};

export default Header;
