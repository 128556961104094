import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import { CiFacebook, CiLinkedin, CiYoutube } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer">
      <hr className="hr"></hr>
      <div className="container">
        <div className="sb_footer section_padding">
          <div className="sb_footer-links_div">
            <h4>Quick Links</h4>
            <NavLink to="/guidelines/privacy" onClick={scrollToTop}>
              <p>Privacy-policy</p>
            </NavLink>
            <NavLink to="/guidelines/cancellation" onClick={scrollToTop}>
              <p>Cancellation-policy</p>
            </NavLink>
            <NavLink to="/guidelines/subscription" onClick={scrollToTop}>
              <p>Legal-Policy</p>
            </NavLink>
            <NavLink to="/guidelines/refunds" onClick={scrollToTop}>
              <p>Terms & Conditions</p>
            </NavLink>
          </div>

          <div className="sb_footer-links_div">
            <h4>Company</h4>
            <NavLink to="/about" onClick={scrollToTop}>
              <p>About</p>
            </NavLink>
            <NavLink to="/guidelines" onClick={scrollToTop}>
              <p>Guidelines</p>
            </NavLink>
            <a href='https://web.bmebharat.com/' onClick={scrollToTop}>
              <p>Forum</p>
            </a>
            <a href='https://web.bmebharat.com/' onClick={scrollToTop}>
              <p>Register</p>
            </a>
          </div>

          <div className="sb_footer-links_div contact-us">
            <h4>Contact Us</h4>
            <ul className="contact-list">
              <li>
                <FaEnvelope
                  className="footer-icon"
                  style={{ marginRight: "5px" }}
                />
                <a
                  style={{
                    color: "black",
                    fontSize: "14px",
                    marginLeft: "5px",
                  }}
                  href="mailto:admin@bmebharat.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  admin@bmebharat.com
                </a>
              </li>
              <li>
                <FaEnvelope
                  className="footer-icon"
                  style={{ marginRight: "5px" }}
                />
                <a
                  className="links"
                  style={{
                    color: "black",
                    fontSize: "14px",
                    marginLeft: "5px",
                  }}
                  href="mailto:bmebharat@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  bmebharat@gmail.com
                </a>
              </li>
              <li style={{ marginRight: "0px" }}>
                <FaPhone
                  className="footer-icon"
                  style={{ marginRight: "0px" }}
                />
                <a
                  style={{
                    color: "black",
                    fontSize: "14px",
                    marginLeft: "5px",
                  }}
                  href="tel:+918310491223"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +91 83104 91223
                </a>
              </li>
              <li style={{ textAlign: "left" }}>
                <FaMapMarkerAlt
                  className="footer-icon"
                  style={{ marginRight: "9px" }}
                />
                <span>Operational Address:</span>
                <br />
                <p className="contact-style">
                  #260, 1st floor, 18th main 11th block Anjanpura, Lal Bahadur
                  Shastri Nagar, Bengaluru-560083, Karnataka.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sb_footer-below">
        <div className="sb_footer-copyright">
          <p style={{ color: "black", fontSize: "14px" }}>
            BME BHARAT &copy; {new Date().getFullYear()} All Rights Reserved.
          </p>
        </div>
        <div className="sb_footer-links_div">
          <a
            href="https://www.facebook.com/bme.bharat/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CiFacebook
              style={{
                width: "26px",
                height: "30px",
                color: "black",
                fontSize: "14px",
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/b_m_e_bharat/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram
              style={{
                width: "22px",
                height: "24px",
                marginRight: "5px",
                color: "black",
                fontSize: "14px",
              }}
            />
          </a>
          <a
            href="https://in.linkedin.com/in/bme-bharat-6859b6201"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CiLinkedin
              style={{
                width: "27px",
                height: "30px",
                color: "black",
                fontSize: "14px",
              }}
            />
          </a>
          <a
            href="https://x.com/BME_BHARAT2020"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaXTwitter
              style={{
                width: "26px",
                height: "20px",
                color: "black",
                fontSize: "14px",
              }}
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCxEPxTe3RhRXlBd3Er4653Q"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CiYoutube
              style={{
                width: "29px",
                height: "25px",
                color: "black",
                fontSize: "14px",
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
